"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hamburger, aside and menu animation
    // *
    // *

    // Define a function to handle the menu behavior
    function toggleMenu() {
        // Get the hamburger, menu, and menu close button and list items
        const hamburger = document.querySelector(".js-menu-hamburger");
        const aside = document.querySelector(".js-menu");
        const menuclose = document.querySelector(".js-menu-close");
        const menuItems = document.querySelectorAll(".js-menu-list li");
    
        // Define a function to animate the menu items forward
        function animateMenuItemsForward() {
            menuItems.forEach(function(item, index) {
                setTimeout(function() {
                    item.classList.add("show");
                }, index * 100);
            });
        }
    
        // Define a function to animate the menu items backward
        function animateMenuItemsBackward() {
            for (let i = menuItems.length - 1; i >= 0; i--) {
                setTimeout(function() {
                    menuItems[i].classList.remove("show");
                }, (menuItems.length - i - 1) * 100);
            }
        }
    
        // Define a function to toggle the menu state
        function toggleMenuState() {
            hamburger.classList.toggle("open");
            aside.classList.toggle("open");

            if (document.body.style.overflow === "hidden") {
                document.body.style.overflow = "auto";
            } else {
                document.body.style.overflow = "hidden";
            }
        }
    
        // Toggle the menu when the hamburger button is clicked
        if (hamburger !== null) {
            hamburger.onclick = function(e) {
                toggleMenuState();

                setTimeout(function() {
                    animateMenuItemsForward();
                }, 300);
            };
        }
    
        // Toggle the menu when the menu close button is clicked
        if (menuclose !== null) {
            menuclose.onclick = function(e) {
                animateMenuItemsBackward();

                setTimeout(function() {
                    toggleMenuState();
                }, menuItems.length * 100);
            };
        }
    
        // Toggle the menu when the 'Esc' key is pressed
        document.addEventListener("keydown", function(event) {
            if (event.key === "Escape") {
                event.preventDefault();
                animateMenuItemsBackward();

                setTimeout(function() {
                    toggleMenuState(); 
                }, menuItems.length * 100);
            }
        });
    
        // Toggle the menu when the user clicks outside of the menu
        document.onclick = function(e) {
            if (e.target.classList.contains("js-menu-close")) {
                toggleMenuState();
            }
        };
    }
    // Call the toggleMenu function to initialize the menu behavior
    toggleMenu();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header
    // * change header background on scroll
    // *

    // Get the header
    const header = document.querySelector(".js-header");

    // Add a scroll event listener to the document to handle the header background and sidebar color
    document.addEventListener('scroll', function(e) {
        if (window.scrollY > 50) {
            header.classList.add('scroll');
        } else {
            header.classList.remove('scroll');
        }
    });


    
    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    if (imageSlider !== null) {
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                autoHeight      : true,
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    // Select the instagramSlider
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    // Check if imageSlider were found
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : true,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 4,
                perMove         : 1,
                speed           : 2000,
                interval        : 5000,
                gap             : '4rem',
                padding: { 
                    left: 0, 
                    right: 0 
                },
                breakpoints: {
                    768: {
                        perMove : 1,
                        perPage : 2,
                        arrows : true
                    },
                    576: {
                        perMove : 1,
                        perPage : 1,
                        padding : '0',
                        arrows : false
                    },
                }
            }).mount();
        }
    };



    /// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    const anchors = document.querySelectorAll(".js-scrollTo");

    for (const anchor of anchors) {
        anchor.addEventListener("click", clickHandler);
    }

    function clickHandler(e) {
        let href = this.getAttribute("href").split("#");
        let newAnchor = "#my" + href[1];
        let targetElement = document.querySelector(newAnchor);

        if (targetElement) {
            let offsetTop = targetElement.offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        } else {
            console.warn("Element with selector ${newAnchor} not found.");
        }
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    window.addEventListener('load', function () {
        let getHash = window.location.hash;
        let elementExists = document.getElementById("my" + getHash.slice(1));

        if (getHash !== null && elementExists !== null) {
            let name = "#my" + getHash.slice(1);
            let offsetTop = document.querySelector(name).offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }

    }, false);


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        // Get all anchor elements on the page
        const links = document.querySelectorAll("a");
    
        // Loop through all anchor elements
        links.forEach(link => {
            // Check if the link has an href attribute and if the hostname is different from the current page's hostname
            if (link.getAttribute("href") && 
                link.hostname !== location.hostname &&
                !link.getAttribute("href").startsWith("javascript:")) {
                
                // Set target attribute to "_blank" to open the link in a new tab/window
                link.target = "_blank";
            }
        });
    };
    
    // Call the function to apply the logic to all links on the page
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 200,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });
});
